import { defineStore } from 'pinia';

export const usePopupStore = defineStore('popupStore', {
    state: () => ({
        activePopup: null,
        popupTitle:'',
        popupMessage: '',
        popupButton:'',
        popupFunction: null,

    }),
    actions: {
        openPopup(popupName, title = '',
                  message = '', buttonText = '',
                  popupFunction = null) {
            this.activePopup = popupName;
            this.popupTitle = title;
            this.popupMessage = message;
            this.popupButton = buttonText;
            this.popupFunction = popupFunction;
        },
        closePopup() {
            this.activePopup = null;
            this.popupTitle = '';
            this.popupMessage = '';
            this.popupButton = '';
            this.popupFunction = null;
        },
    },
});