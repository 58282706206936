<template>
  <div>
    <div class="with-bread-crumbs">
      <span class="h1">{{ $route.query.directionTitle }}</span><span class="sep">/</span>
      <router-link to="/programs">
        Регистрация на программы
      </router-link>
    </div>
    <div class="subjects">
      <router-link
          v-for="subject in subjects"
          :key="subject.id"
          :to="{
              name: 'ProgramsList',
              query: {
                subject_id: subject.id,
                subjectTitle: subject.title,
                directionTitle: $route.query.directionTitle,
                directionId: $route.query.directionId
              }
            }"
          class="subject-item">
        <div class="image">
          <img :src="getImage(subject.id)" alt="Subject Image">
        </div>
        <span class="name">{{ subject.title }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {useProgramsStore} from '@/stores/programsStore';
export default {
  name: 'SubjectsPage',

  computed: {
    subjects() {
      const programsStore = useProgramsStore();
      return programsStore.subjects;
    }
  },

  methods: {
    getImage(id) {
      try {
        return require(`@/assets/images/subjects/${id}.png`);
      } catch (e) {
        return require(`@/assets/images/subjects/default.png`);
      }
    }
  },
  watch: {
    '$route.params.directionId': {
      handler(newDirectionId) {
        const store = useProgramsStore();
        if (newDirectionId) {
          store.fetchSubjectsForDirection(newDirectionId);
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.subjects .name {
  text-align: center;
}
</style>




