<template>
  <div>
    <span class="h1">Мои <span class="red">заявки</span></span>
    <div v-if="registrations.length" class="request-list">
      <div v-for="registration in registrations" :key="registration.id" class="request-item">
        <div :class="`status ${getStatus(registration.status_code)}`">
          {{ registration.status }}
          <span class="yes"
                v-if="registration.status_code === 1 && registration.arrive_confirmation === 1">Участвую</span>
          <span class="no"
                v-if="registration.status_code === 1 && registration.arrive_confirmation === 2">Не участвую</span>
        </div>
        <span class="name">{{ registration.program_title }}</span>
        <span class="subject-classes">{{ registration.subject }}, {{ registration.classes }} класс</span>
        <span class="date">{{ registration.period }}</span>
        <a v-if="registration.link_info" :href="registration.link_info" class="link-with-icon dop-line">Дополнительная
          информация</a>
        <div v-if="registration.pdf_arrive_app_url" class="inputs-list accept-inputs-list">
          <a  v-if="registration.pdf_arrive_app_url_check !== 2" :href="registration.pdf_arrive_app_url"  target="_blank">
            <span class="file-accept"><img src="@/assets/images/Skrepka.svg" alt="Skrepka"></span>
            <p>{{ getApplicationStatus(registration.pdf_arrive_app_url_check) }}</p>
          </a>
          <a v-if="registration.pdf_arrive_app_url_check === 2" href="#" @click="openModal(registration,1)" >
            <span class="file-accept"><img src="@/assets/images/Skrepka.svg" alt="Skrepka"></span>
            <p class="reject-message">{{ getApplicationStatus(registration.pdf_arrive_app_url_check) }}</p>
          </a>
        </div>
        <span class="info-message"
              v-show="registration.pdf_arrive_app_url_check === 2 && registration.application_comment">
          * {{registration.application_comment}}
        </span>
        <a v-if="registration.link_online_event && registration.status_code === 1" :href="registration.link_online_event" class="link-with-icon dop-line">Ссылка
            на мероприятие</a>
        <a v-if="registration.can_be_canceled && registration.status_code === 0" href="javascript:void(0)"
           @click="cancelRegistration(registration.id)" class="cancel">Отменить</a>
        <div class="buttons" v-if="registration.status_code === 1 && registration.arrive_confirmation === 0">
            <a href="javascript:void(0)" class="yes"
               @click="registration.link_info ? openModal(registration,1) : confirmArrival(registration.id,1,[])">
              Участвую
            </a>
            <a href="javascript:void(0)" @click="openModal(registration,0)" class="no">Не участвую</a>
        </div>

      </div>
    </div>
    <div v-else>
      <p>Нет доступных заявок.</p>
    </div>

    <div v-if="showModal" class="shadow">
      <div class="popup">
        <a class="popup-closer" @click="closeModal"><i class="icon-close"></i></a>
        <div  v-if="showBlockApplicationForm">
          <span class="title-to-values">Заявление законного представителя о зачислении на программу</span>
          <div class="inputs-list" v-show="!currentRegistration.pdf_arrive_app_url || currentRegistration.pdf_arrive_app_url_check === 2">
            <a :href="currentRegistration.application_blank_url" target="_blank" download class="change-photo" >
              <span class="file-accept"><img src="@/assets/images/downloading.svg" alt="downloading"></span><span class="file-accept-text">Скачать шаблон</span>
            </a>
            <span class="value-min">
                  Скачайте шаблон, заполните его и загрузите ниже. Допускается загрузка двух файлов jpg, jpeg, png, heic.
                  Размер файла не более 10 Мб.
            </span>
          </div>
          <div class="inputs-list accept-inputs-list">
            <a v-show="!currentRegistration.pdf_arrive_app_url || currentRegistration.pdf_arrive_app_url_check === 2" href="#" class="change-photo" @click.prevent="openFileDialog(currentRegistration.id)">
              <span class="file-accept"><img src="@/assets/images/Skrepka.svg" alt="Skrepka"></span>
              <p v-if ="files[currentRegistration.id]?.length">Файлы прикреплены</p>
              <p v-else>Прикрепить файл</p>
            </a>
            <div class="accept-images" v-if="files[currentRegistration.id]?.length || currentRegistration.pdf_arrive_app_url_check === 2">
              <button class="accept-buttons" @click="openFileDialog(currentRegistration.id)">
                <img src="@/assets/images/Reload.png" alt="Reload">
              </button>
              <button class="accept-buttons" @click="deleteFiles(currentRegistration.id)">
                <img src="@/assets/images/Delete.png" alt="Delete" >
              </button>
            </div>
            <input type="file" multiple accept=".jpeg, .jpg, .heic, .png" class="file-input"
                   :ref="currentRegistration.id"
                   @change="(event)=>handleUploadApplication(event,currentRegistration.id)" />
          </div>
          <div class="button-block request-item request-item-modal">
            <div class="buttons">
              <a :class="`yes ${!!files[currentRegistration.id]?.length?'':'disabled'}`"
                 @click="files[currentRegistration.id]?.length ?
                 confirmArrival(currentRegistration.id, 1, files[currentRegistration.id]) : alertMessage()"
                 :disabled="!files[currentRegistration.id]?.length">
                Отправить
              </a>
            </div>
          </div>
        </div>
        <div v-else class="popup-content">
          <span class="popup-title">Укажите, пожалуйста, причину отказа</span>
          <textarea v-model="declineReason"></textarea>
          <a href="javascript:void(0)" @click="submitDecline" class="button">Подтвердить отмену</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRegistrationsStore} from '@/stores/registrationsStore';
import heic2any from "heic2any";
import Compressor from "compressorjs"
import {useLoadingStore} from "@/stores/loadingStore";

export default {
  name: 'MyRequestsPage',

  data() {
    return {
      showModal: false,
      declineReason: '',
      currentRegistration: null,
      showBlockApplicationForm:false,
      files:{},
    };
  },

  computed: {
    registrations() {
      const store = useRegistrationsStore();
      return store.registrations;
    },


  },

  methods: {
    getStatus(statusCode) {
      switch (statusCode) {
        case 1:
          return 'accepted';
        case 0:
          return 'pending';
        default:
          return 'rejected';
      }
    },

    getApplicationStatus(status) {
      return {
        0: 'Заявление на модерации',
        1: 'Заявление принято',
        2: 'Заявление не принято, прикрепите новые файлы'
      }[status] || 'Неизвестный статус';
    },

    openModal(registration,isAccept) {
      this.showModal = true;
      this.currentRegistration = registration;
      this.showBlockApplicationForm = isAccept;
    },

    closeModal() {
      this.showModal = false;
      this.declineReason = '';
      this.currentRegistration = null;
      this.showBlockApplicationForm = false;
    },

    confirmArrival(id, willArrive, files = []) {
      if (!willArrive) {
        this.openModal(this.registrations.find(el => el.id === id), 0);
        return;
      }
      this.processArrivalConfirmation(id, 1, '', files);
    },

    submitDecline() {
      if (this.declineReason.length > 0) {
        this.processArrivalConfirmation(this.currentRegistration.id, 2, this.declineReason,[] );
        this.closeModal();
      } else {
        alert('Пожалуйста, укажите причину отказа.');
      }
    },

    async processArrivalConfirmation(id, confirmation, reason = '',files) {
      const store = useRegistrationsStore();
     await store.confirmArrival(id, confirmation, reason, files);
     if(files.length){
       this.deleteFiles(id);
     }
      this.closeModal();
    },

    cancelRegistration(id) {
      const store = useRegistrationsStore();
      store.cancelRegistration(id);
    },

    openFileDialog(registrationId) {
      if (this.$refs[registrationId]) {
        this.$refs[registrationId].click();
      }
    },


    async handleUploadApplication(event,registrationId) {
      const loadingStore = useLoadingStore();

      const files = Array.from(event.target.files);
      if (!this.validateFiles(files)) return;

      loadingStore.startLoading();

      const processedFiles = await Promise.all(
          files.map(async (file) => {
            try {
              if (file.name.toLowerCase().endsWith(".heic")) {
                file = await this.convertHeicToJpeg(file);
              }
              return await this.compressFile(file);
            } catch (error) {
              console.error("Ошибка при обработке файла:", file.name, error);
              return null;
            }
          })
      );
      this.files = { ...this.files, [registrationId]: processedFiles.filter(Boolean) };

      loadingStore.stopLoading();
    },

    validateFiles(files) {
      if (files.length !== 2) {
        alert("Загрузи 2 файла одновременно");
        return false;
      }
      for (let file of files) {
        if (file.size > 10 * 1024 * 1024) {
          alert(`Файл ${file.name} превышает 10 МБ!`);
          return false;
        }
      }
      return true;
    },

    alertMessage(){
      alert('Прикрепите подписанное заявление о зачислении')
    },

    async convertHeicToJpeg(file) {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8,
        });
        return new File([convertedBlob], file.name.replace(".heic", ".jpg"), {
          type: "image/jpeg",
        });
      } catch (error) {
        console.error("Ошибка конвертации HEIC:", error);
        throw error;
      }
    },

    async compressFile(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          maxWidth:700,
          maxHeight:1000,
          convertSize: 700000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },

    deleteFiles(registrationId){
      delete this.files[registrationId];
      this.$refs[registrationId].value = '';
    }
  },
};
</script>

<style scoped>
.title-to-values{
  margin: 10px 0 0 0;
  &:after{
    content: "*";
    position: absolute;
    color: red;
    font-size: 12px;
    top: -9px;
    left: -11px;
  }
}

.accept-buttons {
  margin: 10px auto 10px;
  font-size: 16px;
  font-weight: bold;
  border-color: transparent;
  img{
    width: 24px;
  }
}

.accept-buttons:hover{
  transform: scale(1.13);
  cursor:pointer;
}
.file-accept{
  display: flex;
  align-items: center;
  margin-right: 5px;
  img{
    width: 24px;
  }
}
.value-min{
  font-size: small;
  line-height: normal;
}
.file-input{
  display: none;
}
.inputs-list{
  cursor: pointer;
}
.inputs-list_with_info{
  p{
    margin: 0;
  }
}
.accept-inputs-list{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  a{
    display: flex;
  }
}
.accept-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;

  button {
    margin: 0;
    background: none;
  }

  img{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    max-width: 24px;
  }
}
a.disabled{
  &:hover{
    transform: scale(1);
  }
}

 .request-item-modal {
    padding: 0;
}
.popup{
  .request-item{
    box-shadow:none;
    .buttons{
      cursor: pointer;
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

</style>
