<template>
  <footer>
    <div class="content">
      <div class="left">
        <span class="copywright">© 2019-2024 Московская область. Образовательный центр «Взлёт»</span>
      </div>
      <div class="right">
        <div class="contacts-info">
          <a class="email" href="mailto:vzlet@primakov.school">vzlet@primakov.school</a>
          <a class="site" href="https://olympmo.ru/">На сайт</a>

          <button
              @click="$router.push('/logout')"
              class="logout-button">
            Выйти
          </button>

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style scoped>

.logout-button {
  color: var(--blue);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

</style>
