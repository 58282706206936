<template>
  <div class="overlay" v-if="isVisible" @click="closePopup">
    <div class="popup">
      <button class="exit buttons" @click="closePopup"><img src="@/assets/images/exit-black.svg"></button>
      <h2>{{ popupStore.popupTitle }}</h2>
      <p>{{ popupStore.popupMessage }}</p>
      <div class="buttons">
        <button class=" button confirm" @click="confirmAction">{{ popupStore.popupButton }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { usePopupStore } from '@/stores/popupStore';
export default {
  name:'PopupModal',
  computed: {
    popupStore() {
      return usePopupStore();
    },
    isVisible() {
      return !!this.popupStore.activePopup;
    },
  },
  methods: {
    closePopup() {
      this.popupStore.closePopup();
    },
    confirmAction() {
      if (this.popupStore.popupFunction) {
        this.popupStore.popupFunction();
      }
      this.closePopup();
    },

  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.popup{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  max-width: 345px;
  gap: 10px;
  padding: 20px 15px;
  border-radius: 8px;
  background-color: #ffffff;
  h2{
    margin: 18px auto 0;
    text-align: center;
  }
  p{
    text-align: center;
    margin: 0 auto;
  }
}
.exit{
  position: absolute;
  top: 0;
  right: 5px;
  background: none;
}
.buttons {
  margin: 10px auto 10px;
  font-size: 16px;
  font-weight: bold;
  border-color: transparent;
}
.buttons:hover {
  transform: scale(1.13);
}
.button{
  padding: 12px 38px;
}

</style>